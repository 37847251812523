body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  text-align: left;
  white-space: pre;
  -moz-tab-size:    4; /* Firefox 4+ */
  -o-tab-size:      4; /* Opera 11.5 & 12.1 only */
  tab-size:         4; /* Chrome 21+, Safari 6.1+, Opera 15+ */
}

#root {
  height: 100%;
}